// Set container padding to always use the container-padding-x padding.
// Otherwise, Bootstrap 5's make-container mixin uses the gutter-x CSS variable
// when it's defined which changes the container padding depending on whether
// it's the child of a row (since the make-row mixin defines the gutter-x var).
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;
}
