.navbar {
  .form-control {
    border-color: $input-border-color;
    border-radius: $border-radius;
  }
}

.nav-user {
  line-height: 1.2rem;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-nav {
  .nav-link {
    font-weight: $font-weight-bold;
  }
}

.navbar-icon {
  @include font-size($navbar-toggler-font-size);
}
