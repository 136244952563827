.sortable-handle {
  cursor: move;

  .popover {
    cursor: auto;
  }
}

.sortable-ghost {
  border: $black dashed 1px;
  opacity: 0.5;
}
