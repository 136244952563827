.style-library--sidebar {
  border-right: 1px solid;
  height: 100vh;
  position: sticky;
  top: 0;
}

.style-library--color-swatch {
  height: 100px;
  margin: auto;
  text-align: center;
  width: 100px;
}

.style-library--color-info {
  font-size: $font-size-xs;
  text-align: center;
}
