/* Bootstrap doesn't support staggered columns, e.g a layout where
 * columns aren't vertically aligned with each other into discreet rows.
 * However, the relatively-new "column-count" CSS property enables
 * staggered columns, removing the need to depend on JavaScript to
 * dynamically remove whitepsace.
 *
 * (Although older browsers don't support "column-count", the layout
 * will gracefully fall back to a single column in such cases.)
 */

@include media-breakpoint-up(md) {
  .staggered-row {
    column-count: 2;

    .staggered-column {
      break-inside: avoid-column;
      page-break-inside: avoid;
    }
  }
}
