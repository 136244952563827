.thermometer-amount {
  left: 0;
  right: 0;
  text-align: center;
  top: 2%;
}

.flowers-150000 {
  .thermometer-position-0,
  .thermometer-position-1 {
    left: auto;
    right: 50px;
    top: 81%;
  }

  .thermometer-position-2 {
    left: auto;
    right: 50px;
    top: 76%;
  }

  .thermometer-position-3 {
    left: auto;
    right: 50px;
    top: 70%;
  }

  .thermometer-position-4,
  .thermometer-position-5,
  .thermometer-position-6 {
    left: auto;
    right: 50px;
    top: 64%;
  }

  .thermometer-position-7 {
    left: auto;
    right: 50px;
    top: 60%;
  }

  .thermometer-position-8,
  .thermometer-position-9,
  .thermometer-position-10,
  .thermometer-position-11 {
    left: auto;
    right: 50px;
    top: 51%;
  }

  .thermometer-position-12,
  .thermometer-position-13,
  .thermometer-position-14 {
    left: auto;
    right: 50px;
    top: 38%;
  }

  .thermometer-position-15 {
    left: auto;
    right: 50px;
    top: 34%;
  }

  .thermometer-position-16,
  .thermometer-position-17,
  .thermometer-position-18 {
    left: auto;
    right: 50px;
    top: 26%;
  }

  .thermometer-position-19 {
    left: auto;
    right: 50px;
    top: 20%;
  }

  .thermometer-position-20,
  .thermometer-position-21,
  .thermometer-position-22 {
    left: auto;
    right: 50px;
    top: 14%;
  }
}

.leaves-100000,
.leaves-200000 {
  .thermometer-position-0,
  .thermometer-position-1 {
    top: 81%;
  }

  .thermometer-position-2 {
    top: 76%;
  }

  .thermometer-position-3 {
    top: 67%;
  }

  .thermometer-position-4 {
    top: 65%;
  }

  .thermometer-position-5 {
    top: 60%;
  }

  .thermometer-position-6 {
    top: 58%;
  }

  .thermometer-position-7 {
    top: 47%;
  }

  .thermometer-position-8 {
    top: 44%;
  }

  .thermometer-position-9 {
    top: 42%;
  }

  .thermometer-position-10 {
    top: 39%;
  }

  .thermometer-position-11,
  .thermometer-position-12 {
    top: 29%;
  }
}
