// Global variables (overrides of Bootstrap 5 variables).  Originals:
// https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
//
// Note that some of these variables are copied in the mailer sass variables:
// app/assets/stylesheets/mailer/_variables.sass.erb

@import './colors';

// Options
$enable-smooth-scroll: false !default;

// Minimum/maximum height and width
//
// Pre-set sizes for minimum/maximum width & height elements.
// Add more entries as necessary.
$extremum-sizes: 3px, 100px, 420px, 550px; // fpf


// Body
//
// Settings for the `<body>` element.

$body-bg:                     $gray-100 !default;
$body-color:                  $gray-800 !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           1.5rem !default;


// Container padding

$container-padding-x:         1rem !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-xs:              1.5 !default; // fpf
$line-height-xxs:             1.25 !default; // fpf

$border-color:                $gray-300 !default;
$dropdown-border-color:       rgba($black, .175) !default;

$border-radius-xs:            0.2rem !default; // fpf
$border-radius-xxs:           0.5rem !default; // fpf
$border-radius-xxxs:          0.1rem !default; // fpf

$component-active-bg:         $secondary !default;

$focus-ring-opacity:          0.25 !default;
$focus-ring-color:            rgba($secondary, $focus-ring-opacity) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Add Lato to Bootstrap's default list of fonts
// stylelint-disable value-keyword-case
/* stylelint-disable-next-line max-line-length */
$font-family-sans-serif:      Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default; // 20px
$font-size-sm:                $font-size-base * 0.875 !default; // 14px
$font-size-smer:              $font-size-base * 0.8125 !default; // 13px - fpf
$font-size-xs:                $font-size-base * 0.75 !default; // 12px - fpf
$font-size-xxs:               $font-size-base * 0.6875 !default; // 11px - fpf
$font-size-half:              $font-size-base * 0.5 !default; // 8px - fpf
$small-font-size:             0.8em !default;

// fpf font sizes
$extra-font-sizes: (
  'half': $font-size-half,
  'xxs': $font-size-xs,
  'xs': $font-size-xs,
  'smer': $font-size-smer,
  'sm': $font-size-sm,
  'md': $font-size-base,
  'lg': $font-size-lg,
);

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$h1-font-size:                $font-size-base * 2.25 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.375 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1.125 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      1rem !default;
$headings-font-weight:        $font-weight-bold !default;
$headings-color:              $gray-800 !default;

$label-font-size:             $font-size-base !default; // fpf
$label-font-weight:           $font-weight-bold !default; // fpf
$label-text-transform:        none !default; // fpf
$label-letter-spacing:        normal !default; // fpf


// Links
//
// Style anchor elements.

$link-color:                  $orange-400 !default;
$link-decoration:             none !default;
$link-hover-decoration:       underline !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-bg:                    transparent !default;
$table-border-color:          $border-color !default;

$table-group-separator-color: $table-border-color !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y-xs:      0.25rem !default; // fpf
$input-btn-padding-x-xs:      0.5rem !default; // fpf
$input-btn-font-size-xs:      $font-size-xs !default; // fpf
$input-btn-line-height-xs:    $line-height-xs !default; // fpf

$input-btn-padding-y-xxs:      0.05rem !default; // fpf
$input-btn-padding-x-xxs:      0.3rem !default; // fpf
$input-btn-font-size-xxs:      $font-size-xs !default; // fpf
$input-btn-line-height-xxs:    $line-height-xs !default; // fpf

$input-btn-padding-y-xxxs:      0 !default; // fpf
$input-btn-padding-x-xxxs:      0.2rem !default; // fpf
$input-btn-font-size-xxxs:      $font-size-xxs !default; // fpf
$input-btn-line-height-xxxs:    $line-height-xxs !default; // fpf


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight:             600 !default;

$btn-padding-y-xs:            $input-btn-padding-y-xs !default; // fpf
$btn-padding-x-xs:            $input-btn-padding-x-xs !default; // fpf
$btn-font-size-xs:            $input-btn-font-size-xs !default; // fpf
$btn-border-radius-xs:        $border-radius-xs !default; // fpf

$btn-padding-y-xxs:           $input-btn-padding-y-xxs !default; // fpf
$btn-padding-x-xxs:           $input-btn-padding-x-xxs !default; // fpf
$btn-font-size-xxs:           $input-btn-font-size-xxs !default; // fpf
$btn-border-radius-xxs:       $border-radius-xxs !default; // fpf

$btn-padding-y-xxxs:          $input-btn-padding-y-xxxs !default; // fpf
$btn-padding-x-xxxs:          $input-btn-padding-x-xxxs !default; // fpf
$btn-font-size-xxxs:          $input-btn-font-size-xxxs !default; // fpf
$btn-border-radius-xxxs:      $border-radius-xxxs !default; // fpf


// Forms

$label-margin-bottom:         0.5rem !default; // fpf

$form-label-margin-bottom:    $label-margin-bottom !default;
$form-group-margin-bottom:    1rem !default; // fpf

$input-bg:                    $white !default;

$input-border-color:          $border-color !default;
$input-placeholder-color:     $gray-500 !default;

$input-border-radius:         0 !default;
$input-border-radius-lg:      $input-border-radius !default;
$input-border-radius-sm:      $input-border-radius !default;

$form-select-bg-size:                           0.5625em !default; // scale to container font size
$form-select-indicator-color:                   $gray-800 !default;
$form-select-indicator:                         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#{$form-select-indicator-color}' stroke='#{$form-select-indicator-color}' stroke-width='2' d='m2,5l6,8l6,-8l-12,0z'/></svg>") !default;


// Form validation

$form-feedback-warning-color:       $warning !default; // fpf

$form-feedback-icon-warning-color:  $form-feedback-warning-color !default; // fpf
$form-feedback-icon-warning:        '' !default; // fpf

// fpf validation states
$extra-form-validation-states: (
  // `warning` implies a potential error but not one that should prevent form submission
  'warning': (
    'color': $form-feedback-warning-color,
    'icon': $form-feedback-icon-warning
  ),
);


// Z-index master list

$zindex-table-header-sticky-col:    1 !default; // fpf
$zindex-select2-container:          1 !default; // fpf, matches select2-bootstrap-5-theme
$zindex-table-header-sticky:        2 !default; // fpf
$zindex-spinner-container:          900 !default; // fpf
// Make dropdowns visible above sticky elements and modals
// https://github.com/twbs/bootstrap/issues/31747
$zindex-dropdown:                   1025 !default; // fpf
$zindex-select:                     1075 !default; // fpf


// Navbar

$navbar-light-color:                $gray-700 !default;
$navbar-light-active-color:         $secondary !default;
$navbar-light-toggler-border-color: transparent !default;


// Dropdowns

$dropdown-link-color:               $gray-900 !default;
$dropdown-bg:                       $white !default;
$dropdown-header-color:             $secondary !default;
$dropdown-link-hover-bg:            $gray-200 !default;


// Pagination

$pagination-color:                  $secondary !default;
$pagination-bg:                     transparent !default;
$pagination-border-width:           0 !default;

$pagination-hover-color:            shade-color($pagination-color, 30%) !default;

$pagination-active-color:           $pagination-color !default;
$pagination-active-bg:              $pagination-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-bg:            $pagination-bg !default;


// Jumbotron

$jumbotron-padding:                 1rem !default; // fpf
$jumbotron-margin-bottom:           2rem !default; // fpf
$jumbotron-margin-bottom-sm:        0.5rem !default; // fpf
$jumbotron-color:                   null !default; // fpf
$jumbotron-bg:                      $gray-190 !default; // fpf


// Cards

$card-spacer-y:                     1.875rem !default;
$card-spacer-x:                     1.875rem !default;
$card-border-color:                 $border-color !default;
$card-spacer-y-sm:                  0.75rem !default; // fpf
$card-spacer-x-sm:                  1.25rem !default; // fpf
$card-margin-bottom:                1.5rem !default; // fpf

$card-bg:                           $white !default;

// Tooltips

$tooltip-font-size:                 $font-size-base !default;


// Popovers

$popover-max-width:                 576px !default;


// Badges

$badge-border-left-width:           0.5rem !default; // fpf
$badge-border-radius:               0.125rem !default;


// Breadcrumbs

$breadcrumb-padding-x:              0 !default;

$breadcrumb-bg:                     transparent !default;


// select2-bootstrap-5 theme

$s2bs5-placeholder-color:           $gray-600 !default;
$s2bs5-bg:                          $white !default;
$s2bs5-clear-padding-x:             0 !default;
$s2bs5-multi-item-padding-y:        0 !default;
$s2bs5-multi-item-padding-x:        0.5em !default;
