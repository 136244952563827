// Legacy styles for HTML (especially in CMS data) that has not yet been updated
// for the 2020 styles.  These classes should not be used in any re-design HTML.

/* stylelint-disable scss/at-extend-no-missing-placeholder */

// Use .card (via the 'card' component) instead
.box {
  @extend .card;

  > .content {
    @extend .card-body;
  }
}

// Use .collapse instead
.hide,
.hidden {
  &:not(.show) {
    display: none;
  }
}

// Used by some CMS pages
.learn-more-list {
  @extend .list-unstyled;

  li {
    @extend .mb-3;
  }

  .pic,
  .link {
    display: inline;
  }
}

// Used by some CMS pages.  Use .btn insetad
.btn-donate {
  @extend .btn;
}
