// stylelint-disable selector-no-qualifying-type

label,
legend,
.label,
.legend {
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  letter-spacing: $label-letter-spacing;
  margin-bottom: $label-margin-bottom; // restore BS 4 default margin for labels
  text-transform: $label-text-transform;
}

input[type='checkbox'] + label:not(.btn),
input[type='radio'] + label:not(.btn) {
  font-weight: $font-weight-normal;
}

label.is-invalid,
.form-label.is-invalid {
  color: $form-feedback-invalid-color;
}

.warning-feedback {
  color: $danger; // $warning is too hard to read
}

// Warning validation state doesn't use icon and so doesn't need padding-right
.form-control.is-warning,
textarea.form-control.is-warning {
  padding-right: $input-padding-x;
}

// Standardize gutters in form rows (this class was dropped in BS 5 in favor of
// gutter utility classes).
.form-row {
  --bs-gutter-x: 0.5rem;
}

// Standardize margins in form groups (this class was dropped in BS 5 in favor of
// margin utility classes).
.form-group {
  margin-bottom: $form-group-margin-bottom;

  // Remove extra margin from below checkbox inputs for tighter vertical spacing
  .form-check,
  .form-check-label {
    margin-bottom: 0;
  }
}

// Allow a custom UI for an input by sizing it to fill its parent and making it
// transparent.
.input-custom-hidden {
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// Allow a custom UI for a calendar date picker
input[type='date'].input-custom-hidden::-webkit-calendar-picker-indicator {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// The Simple Form CollectionInput doesn't appear to support label_html or
// legend_html options to supply a class to the checkbox or radio button
// collections, so this class can be applied to the wrapper (via wrapper_html)
// to make the legend (the overall label for a collection) bold.
.form-legend-bold legend {
  font-weight: bold;
}

// Make links in form field invalid feedback text stand out more
.invalid-feedback a {
  color: $primary;
  text-decoration: underline;
}
