/* stylelint-disable selector-class-pattern */

/*
 * Prevent the customizable uppy container note (e.g. "Attach up to 5 images.
 * Limit 8 MB per image.") from being hidden unnecessarily at short container
 * heights.
 *
 * Also, set a z-index so the note doesn't cover the "browse files" button.
 */

.uppy-Dashboard-AddFiles-title {
  z-index: 1;
}

.uppy-Dashboard-AddFiles-info {
  display: block !important;
  z-index: 0;
}

.card.uppy-placeholder {
  border-color: #eaeaea !important;
  height: 472px;

  .card-header {
    background-color: #fafafa !important;
    border-bottom-color: #eaeaea !important;
    font-size: 14px !important;
  }

  .card-body {
    background-color: #f4f4f4 !important;
    padding: 10px 0 !important;
  }

  .card-footer {
    background-color: #f4f4f4 !important;
    border-top-color: transparent !important;
  }

  .uppy-placeholder-thumbnail {
    padding: 5px 15px 30px;
    width: 25%;
  }

  .uppy-placeholder-thumbnail img {
    height: 120px;
    object-fit: cover;
    width: 100%;
  }

  a {
    color: #2275d7;
  }
}
