.tooltip.text-start .tooltip-inner {
  text-align: left;
}

.tooltip.tooltip-bs-popover-style {
  opacity: 1 !important;

  .tooltip-inner {
    background-color: $white;
    border: $popover-border-width solid $popover-border-color;
    color: $black;
    max-width: $popover-max-width;
    padding: $popover-body-padding-y $popover-body-padding-x;
    text-align: left;
  }
}

.tooltip.tooltip-bs-popover-style.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $popover-border-color;
}

.tooltip.tooltip-bs-popover-style.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $popover-border-color;
}

.tooltip.tooltip-bs-popover-style.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $popover-border-color;
}

.tooltip.tooltip-bs-popover-style.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $popover-border-color;
}

// Better styling for lists in tooltips
/* stylelint-disable scss/at-extend-no-missing-placeholder */
.tooltip-inner ul {
  @extend .mb-0;
  @extend .ps-3;
  @extend .text-start;
}
