@use 'sass:map';

// Re-assigned maps
//
// Merge additional values into Bootstrap maps here, after:
//  - the extra values have been defined
//  - the original values from Bootstrap have been imported
// and before:
//  - the bootstrap-defined maps are imported (see bottom of this file)
//  - the maps are used by Bootstrap code (e.g. by utilities/api)

$grays: map.merge($grays, $extra-grays);
$colors: map.merge($colors, $extra-colors);
$theme-colors: map.merge($theme-colors, $extra-theme-colors);
$font-sizes: map.merge($font-sizes, $extra-font-sizes);
$form-validation-states: map.merge($form-validation-states, $extra-form-validation-states);

@import '~bootstrap/scss/maps';
