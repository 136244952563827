// Add a thick solid left border to badges to distinguish them visually from buttons
.badge {
  border-left-style: solid;
  border-left-width: $badge-border-left-width;

  &.badge-dot,
  &.rounded-pill {
    border-style: none;
  }
}

// Fix for Bootstrap removing borders from all things in bordered tables with this rule:
// .table-bordered > :not(caption) > * { border-width: $table-border-width 0; }
.table-bordered .badge {
  border-left-width: $badge-border-left-width;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    background-color: $value;
    color: color-contrast($value);

    @if $color == 'dark' {
      border-left-color: tint-color($value, 30%);
    }
    @else {
      border-left-color: shade-color($value, 30%);
    }
  }
}

.badge-purple {
  background-color: $purple;
  border-left-color: shade-color($purple, 30%);
  color: color-contrast($purple);
}

.badge-dot {
  border-radius: 50%;
  height: 20px;
  width: 20px;

  &:empty {
    display: block;
  }
}
