.wizard-icon {
  background: url('~images/wizard/circle.png') 0 0 no-repeat;
  height: 27px;
  margin-right: $spacer * 0.5;
  text-align: center;
  width: 27px;
}

.wizard-step {
  margin: ($spacer * 0.5) $spacer ($spacer * 0.5) 0;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  &.done .wizard-icon {
    background-image: url('~images/wizard/circle-done.png');
  }

  &.done .wizard-icon-label {
    display: none;
  }
}

.wizard {
  margin-bottom: 0;
}
