.jumbotron {
  background-color: $jumbotron-bg;
  color: $jumbotron-color;
  margin-bottom: $jumbotron-margin-bottom;
  padding: $jumbotron-padding ($jumbotron-padding * 0.5);

  @include media-breakpoint-up(sm) {
    padding: ($jumbotron-padding * 2) $jumbotron-padding;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: $jumbotron-margin-bottom-sm;
  }

  > :last-child,
  .container > :last-child {
    margin-bottom: 0;
  }
}

.jumbotron-fluid {
  padding-left: 0;
  padding-right: 0;
}
