@import 'highlight-within-textarea/jquery.highlight-within-textarea';

.hwt-container {
  max-width: 1200px;
  width: 100%;
}

.hwt-backdrop {
  background-color: #ffffff !important;
  margin: 0 !important;
}

.hwt-content {
  border: none; // stylelint-disable declaration-property-value-disallowed-list
  margin: 0 !important;
  padding: 5px !important;
}

.hwt-content mark {
  background-color: #ffec99;
}
