// Staging Banner to help folks keep track of which tab they're on
//
// math used to calculate the top and right fixed position offsets:
// top = (((width / 2) ** 2) / 2) ** 0.5
// right = top - width / 2

.staging-banner {
  color: #ffffff;
  font: 18px/24px Lato, sans-serif;
  position: fixed;
  right: -20.5px;
  text-align: center;
  top: 49.5px;
  transform: translateY(-50%) rotate(45deg) translate(0, -12px);
  user-select: none;
  width: 140px;
  z-index: 1000;
}
