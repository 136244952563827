/* stylelint-disable selector-class-pattern, selector-max-compound-selectors, max-line-length, max-nesting-depth */

// Import Select2 and bootstrap theme sass
@import 'select2/src/scss/core';

// Fix for select2-bootstrap-5-theme 1.3.0 not supporting Bootstrap 5.3.0 (and maybe not 5.2.3)
// https://github.com/apalfrey/select2-bootstrap-5-theme/issues/75#issuecomment-1573265695
$s2bs5-border-color: $border-color;

@import 'select2-bootstrap-5-theme/src/include-all';

.select2-container--bootstrap-5 {
  max-width: 100%;
  min-width: 150px;
}

// Match placeholder styling of native elements
.select2-selection__placeholder {
  color: $input-placeholder-color;
  font-weight: $font-weight-normal;
}

.select2-container--bootstrap-5 .select2-results__group {
  border-top: $border-width solid $border-color;
  color: $gray-600;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  padding-top: $spacer * 0.5;
}

// Override select2-bootstrap-5-theme, which tries to set font size and weight
.select2-container--bootstrap-5 .select2-selection {
  font-size: inherit;
  font-weight: inherit;

  .select2-selection__rendered {
    font-weight: inherit;
  }
}

// The onSelect2ResultsGroupClick helper makes groups clickable
.select2-dropdown--multiple .select2-results__group {
  cursor: pointer;

  &:hover {
    background-color: $light;
  }
}

.select2-container--open {
  z-index: $zindex-select;
}

// Fix for multiple select element opening native select UI on iOS
// https://github.com/select2/select2/issues/4772
.select2-hidden-accessible {
  display: none !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

// Move search field inline with selections
.select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
  float: left;
  width: auto;
}

// Revert select2-bootstrap-5-theme's use of a background image for the
// selection clear button with select2's use of "x" text.
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  background: none;
  border: 0;
  color: $gray-500;
  cursor: pointer;
  font-weight: $font-weight-bold;
  height: auto;
  margin: 0 1px 0 0;
  padding: 0 3px 0 0;
  text-indent: unset;
  width: auto;

  &:hover {
    background: none;
    color: $gray-800;
  }

  > span {
    display: inline;
  }
}

// Used to add a button after the select2 dropdown on the same line and aligned with the dropdown
.select2-btn {
  padding: 0;

  > a {
    margin: 22px 0 0;
  }
}

// Fix for margins/padding around multiple select items to avoid showing extra
// space below the last line of selected elements
.select2-container--bootstrap-5 .select2-selection--multiple {
  padding: 0 5px 5px 0;

  .select2-selection__rendered {
    .select2-search--inline {
      margin-left: 5px;
      margin-top: 5px;
    }

    .select2-selection__choice {
      margin: 5px 0 0 5px;
    }
  }
}

// Used to add a dropdown down arrow indicator to multi select2 inputs
/* stylelint-disable-next-line no-duplicate-selectors */
.select2-container .select2-selection--multiple {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23333' stroke='%23333' stroke-width='2' d='M2 5l6 8 6-8H2z'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5625em;
  padding-right: 3.25rem;

  // Reposition the x icon (used for clearing a selection) further inwards
  // to make space for the dropdown indicator
  .select2-selection__clear {
    right: 2.25rem;
  }
}
