// Import flatpickr sass
@import '~flatpickr/dist/flatpickr';

// stylelint-disable selector-no-qualifying-type, selector-class-pattern

// 100% calendar styling
.flatpickr-calendar.w-100 {
  .flatpickr-days {
    width: 100%;
  }

  .dayContainer {
    max-width: none;
    width: 100%;
  }

  .flatpickr-day {
    max-width: none;
  }
}

// Fix showing placeholder in iOS date fields.  Note the placeholder is also
// not shown in Chrome on Android, but there's no simple way to target Android
// in a CSS selector.
//
// https://github.com/flatpickr/flatpickr/issues/1103
// https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices
@supports (-webkit-touch-callout: none) {
  input.flatpickr-mobile::after {
    color: $input-placeholder-color;
    content: attr(placeholder) !important;
  }

  input[value]:not([value='']) + input.flatpickr-mobile::after {
    content: '' !important;
  }
}
