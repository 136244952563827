.box-shadow-0 {
  box-shadow: none !important;
}

.border-style-solid {
  border-style: solid !important;
}

.border-green-500 {
  border-color: $green-500 !important;
}

.grass {
  background: transparent url('~images/borders/grass.png') 0 0 repeat-x;
  height: 56px;
  width: 100%;
}
