// fonts
// https://fontawesome.com/
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
// http://www.latofonts.com/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

// Bootstrap
//
// This order is important:
// 1. FPF variables are imported (before this init file is imported)
// 2. Bootstrap config is imported (functions, variables, mixins)
// 3. Bootstrap maps are updated with FPF variables (so that the Bootstrap styles
//    and utilities use any additional values we've defined)
// 4. Bootstrap styles are imported
// 5. FPF stylesheets are imported, which may add to the $utilities map
// 6. Bootstrap utilties api is imported, which turns $utilities into actual CSS rules
@import 'bootstrap/config'; // BS step 2
@import 'bootstrap/maps'; // BS step 3
@import 'bootstrap/styles'; // BS step 4

// Vendor libraries
@import 'add-to-calendar-button/assets/css/atcb.css';
@import 'flatpickr/flatpickr';
@import 'highlight_within_textarea/highlight_within_textarea';
@import 'select2/select2';
@import 'simplelightbox/dist/simple-lightbox.css';
@import '@splidejs/splide/dist/css/splide.min';
@import '@uppy/core/dist/style.min';
@import '@uppy/dashboard/dist/style.min';
@import '@uppy/image-editor/dist/style.min.css';

// Stylesheets (BS step 5)
@import 'stylesheets/animation';
@import 'stylesheets/badge';
@import 'stylesheets/borders';
@import 'stylesheets/buttons';
@import 'stylesheets/card';
@import 'stylesheets/colors';
@import 'stylesheets/container';
@import 'stylesheets/extremum_sizing';
@import 'stylesheets/force_display';
@import 'stylesheets/forms';
@import 'stylesheets/icons';
@import 'stylesheets/images';
@import 'stylesheets/jumbotron';
@import 'stylesheets/legacy';
@import 'stylesheets/pagination';
@import 'stylesheets/popovers';
@import 'stylesheets/reset';
@import 'stylesheets/spacing';
@import 'stylesheets/sizing';
@import 'stylesheets/staggered_columns';
@import 'stylesheets/tabs';
@import 'stylesheets/tables';
@import 'stylesheets/tooltips';
@import 'stylesheets/type';

// Bootstrap utilities api (BS step 6)
@import 'bootstrap/utilities_api';

// Components
@import 'components/ads/ads';
@import 'components/excerpt/excerpt';
@import 'components/forum/forum';
@import 'components/forum/post/post';
@import 'components/highlight_fields/highlight_fields';
@import 'components/map/map';
@import 'components/page/footer/footer';
@import 'components/page/header/header';
@import 'components/page/navbar/navbar';
@import 'components/page/staging_banner/staging_banner';
@import 'components/payments/credit_card/credit_card';
@import 'components/slider/slider';
@import 'components/spinner/spinner';
@import 'components/sortable/sortable';
@import 'components/style_library/style_library';
@import 'components/uppy/uppy';
@import 'components/wizard/wizard';
