.table-layout-fixed {
  table-layout: fixed;
}

//
// Condensed table w/ half vertical padding
//

.table-y-sm {
  th,
  td {
    padding-bottom: $table-cell-padding-y-sm;
    padding-top: $table-cell-padding-y-sm;
  }
}

// Column highlighting on hover
//
// https://css-tricks.com/simple-css-row-column-highlighting/
.table-hover-col {
  overflow: hidden;

  th,
  td {
    position: relative;
  }

  td:hover::after,
  th:hover::after {
    background-color: $table-hover-bg;
    content: '\00a0';
    height: 10000px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -5000px;
    width: 100%;
  }
}

.table-admin {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .table-hover;

  thead th {
    background-color: $gray-200;
    // Use a box-shadow instead of a border so that it remains visible when
    // using a scrolled sticky header
    box-shadow: inset 0 -1px $black;
    font-weight: $font-weight-bold;
  }
}

// Disable hover effect
//
// May be placed on the table or the tr
.table-hover-none {
  /* stylelint-disable-next-line selector-max-compound-selectors */
  &:hover > *,
  > tbody > tr:hover > * {
    --bs-table-accent-bg: none !important;
  }
}

// Sticky header
//
// The sticky th elements need a background color applied (which is done by default
// for .table-admin tables).
//
// stylelint-disable-next-line selector-max-compound-selectors
.table-header-sticky > * > tr > th {
  left: 0;
  position: sticky;
  top: 0;
  // start new stacking context to be above buttons in rows
  z-index: $zindex-table-header-sticky;
}

// Sticky thead
//
// This is amodification of the above table-header-sticky class, and applies to
// the entire thead rather than th elements.  It's most useful for thead elements
// that contain multiple rows that should all remain visible while scrolling.
.table-thead-sticky > thead {
  left: 0;
  position: sticky;
  top: 0;
  // start new stacking context to be above buttons in rows
  z-index: $zindex-table-header-sticky;
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.table-header-sticky > :not(thead) > tr > th {
  // set non-thead th elements below their thead cousins so that when a sticky
  // header table with a header row and a header column is scrolled, the column
  // cells go underneath the header row
  z-index: $zindex-table-header-sticky-col;
}

// Responsive stacking tables
//
// Generate series of `.table-responsive-stack-*` classes for configuring the
// screen size of where your table will stack.
//
// When the table cells are stacked, the thead is hidden and the padding is
// shifted to the last cell in each row.
//
// Based on https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_navbar.scss#L160
.table-responsive-stack {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($next) {
        thead {
          display: none;
        }

        th,
        td {
          border-style: none;
          display: block;
          padding-bottom: 0;
          padding-top: 0;
        }

        th:first-child,
        td:first-child {
          padding-top: $table-cell-padding-y;
        }

        th:last-child,
        td:last-child {
          padding-bottom: $table-cell-padding-y;
        }
      }
    }
  }
}

// Change links in a table-info to match table-info color
.table-info a {
  color: shade-color($info, 20%);
}

.table-bordered-x-white > td {
  border-left-color: $white;
  border-right-color: $white;
}
