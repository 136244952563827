// The contrast ratio to reach against white, to determine if color changes
// from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
//
// Bootstrap 5 uses 4.5 by default, but that causes the FPF primary orange to
// use black as the contrast color rather than white.  Switch to 3 to preserve
// white text on orange buttons, badges, etc.
$min-contrast-ratio:   3 !default;

// Color system

$white:           #ffffff !default;
$gray-100:        #f5f5f9 !default;
$gray-190:        #ebecf1 !default;
$gray-200:        #e8e9f2 !default;
$gray-green-200:  #e7eef1 !default;
$gray-300:        #d2d6e1 !default;
$gray-400:        #ced4da !default;
$gray-500:        #adb5bd !default;
$gray-600:        #6c757d !default;
$gray-700:        #495057 !default;
$gray-800:        #333333 !default;
$gray-900:        #212529 !default;
$black:           #000000 !default;

// fpf grays
$extra-grays: (
  '190': $gray-190,
  'green-200': $gray-green-200,
);

$red:             #c62308 !default;
$green:           #3a860c !default;
$orange:          #e87211 !default;
$yellow:          #fbd203 !default;
$cyan:            #84c0c6 !default;
$purple:          #6f42c1 !default;

// Unused Bootstrap colors
$blue:            #0d6efd !default;
$indigo:          #6610f2 !default;
$teal:            #20c997 !default;
$pink:            #d63384 !default;

$red-600:         #803333 !default;

$orange-100:      #f9ede0 !default;
$orange-400:      #cc4e0f !default;

$yellow-100:      #fbfbd8 !default;

$green-200:       #e5ecdb !default;
$green-400:       #b2e874 !default;
$green-500:       #91a84e !default;

$blue-300:        #c5d7df !default;
$blue-600:        #355768 !default;

$pink-100:        #ffc6c1 !default;

// fpf colors
$extra-colors: (
  'red-600': $red-600,
  'orange-100': $orange-100,
  'orange-400': $orange-400,
  'yellow-100': $yellow-100,
  'green-200': $green-200,
  'green-400': $green-400,
  'green-500': $green-500,
  'blue-300': $blue-300,
  'blue-600': $blue-600,
  'pink-100': $pink-100,
);

$primary:         $orange !default;
$secondary:       $green !default;
$success:         $green !default;
$info:            $cyan !default;
$warning:         $yellow !default;
$danger:          $red !default;
$light:           $gray-200 !default;
$dark:            $gray-800 !default;

// fpf theme colors

$extra-theme-colors: (
  'gray': $gray-500,
);
