h3,
.h3 {
  color: $blue-600;
}

small,
.small {
  font-weight: $font-weight-normal;
}

.text-muted {
  color: $gray-600;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%line-clamp {
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
}

.line-clamp-2 {
  @extend %line-clamp;

  -webkit-line-clamp: 2;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

// Font size utilities

@each $size, $value in $font-sizes {
  .font-size-#{$size} { font-size: $value; }
}

.text-help {
  color: $gray-700;
  font-style: italic;
}

.text-link {
  color: $orange-400;
}

.text-link:hover {
  color: shade-color($orange-400, 30%);
  cursor: pointer !important;
  text-decoration: underline !important;
}

.text-decoration-underline-dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.line-height-150 {
  line-height: 1.5;
}

.line-height-200 {
  line-height: 2;
}

// Responsive font sizes (e.g. fs-sm-xs to use an extra small font in small grids)
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    @each $size, $value in $font-sizes {
      .fs-#{$breakpoint}-#{$size} {
        font-size: $value;
      }
    }
  }
}
