@use 'sass:math';

.card-title {
  color: $blue-600;
}

.card-body {
  > :last-child {
    margin-bottom: 0;
  }
}

.card {
  margin-bottom: $card-margin-bottom;
}

// Cards in a column
.card-col {
  .card {
    margin-bottom: math.div($card-margin-bottom, 3);
  }

  .card:last-child {
    margin-bottom: $card-margin-bottom;
  }
}

.card-selected {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .alert-info;
}

.card-hover {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .bg-light;
}

.card-sm {
  .card-body {
    padding: math.div($card-spacer-x, 2);
  }

  .card-header {
    padding: math.div($card-cap-padding-y, 2) math.div($card-cap-padding-x, 2);
  }
}

// sm margin and padding for cards
@include media-breakpoint-down(md) {
  .card-title {
    margin-bottom: $card-spacer-y-sm;
  }

  .card-subtitle {
    margin-top: math.div(-$card-spacer-y-sm, 2);
  }

  .card-body {
    padding: $card-spacer-x-sm;
  }

  .card-link + .card-link {
    margin-left: $card-spacer-x-sm;
  }

  .card-header {
    padding: $card-spacer-y-sm $card-spacer-x-sm;
  }

  .card-footer {
    padding: $card-spacer-y-sm $card-spacer-x-sm;
  }

  .card-header-tabs {
    margin-bottom: -$card-spacer-y-sm;
    margin-left: math.div(-$card-spacer-x-sm, 2);
    margin-right: math.div(-$card-spacer-x-sm, 2);
  }

  .card-header-pills {
    margin-left: math.div(-$card-spacer-x-sm, 2);
    margin-right: math.div(-$card-spacer-x-sm, 2);
  }
}


// responsive card classes that remove card styling at smaller breakpoints.
// For example, .card.unstyle-card-down-md will undo card styling at xs and sm sizes.
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .card.unstyle-card-down-#{$breakpoint} {
      background-color: inherit !important;
      border-width: 0 !important;

      .card-body {
        background-color: inherit !important;
        padding: 0 !important;
      }
    }
  }
}
