@use 'sass:color';

//
// Button styles
//

.btn-link {
  font-weight: $font-weight-bold; // make all links bold

  // Don't underline icon buttons
  &.fas,
  &.far {
    text-decoration: none;
  }
}

// Oval / circle buttons
.btn-oval {
  border-radius: 20px;
}

// Switch button to primary color scheme when checked
.btn-check:checked:not(.disabled) + .btn-checked-primary {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}

// Switch button to secondary color scheme when checked
.btn-check:checked:not(.disabled) + .btn-checked-secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}

// Don't change styling when button has focus
.btn-no-focus:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

// Hide buttons until their hover container ancestor is hovered (but only on
// devices that support hover via the primary input mechanism, meaning these
// elements will always be visible on phones)
@media (hover: hover) {
  .btn-hover-container {
    .btn-hover-show {
      opacity: 0;
    }

    &:hover .btn-hover-show {
      opacity: 1;
    }
  }
}


//
// Button styles for specific purposes
//

// Pill-shaped button for posting category
.btn-category {
  border-radius: 2px;
}

.btn-category-default {
  @include button-variant($gray-green-200, $gray-green-200);

  color: $gray-800;
}

// Apply an event icon to an event button (assumes bnt-category is also applied)
.btn-category-event {
  background-image: url('~images/icons/category-event-icon.svg');
  background-position: left 6px center;
  background-repeat: no-repeat;
  background-size: 14px 13px;
  padding-left: 25px;
}

// Dark pill-shaped button for ad category
.btn-category-ad {
  @include button-variant($gray-600, $gray-600);

  color: $white;
}

// Pill-shaped button for business category
.btn-business-category {
  @include button-variant($green, $green);

  color: $white;
  font-weight: $font-weight-normal;
}

// Disable the element when a parent element has the aria-busy=true attribute,
// which is added by turbo frames during form submission
[aria-busy='true'] .disabled-busy-parent {
  background-color: $white;
  opacity: $btn-disabled-opacity;
  pointer-events: none;
}

//
// Button colors
//

$warning-button-color: color.scale($warning, $lightness: -40%); // stylelint-disable function-no-unknown
$info-button-color: color.scale($info, $lightness: -40%);

.btn-link-warning {
  color: $warning-button-color;
}

// Dark orange text on light orange background
.btn-reverse-primary {
  @include button-variant($orange-100, $orange-100);

  color: $orange-400;
}

// Colored btn-link
@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    color: $value;

    &:hover {
      color: shade-color($value, 30%);
    }
  }
}

// White background for btn-outline-*
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background-color: $white;
  }
}

// fix legibility of btn-outline-light
.btn-outline-light {
  border-color: $border-color;
  color: $gray-800;

  &:hover,
  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    border-color: $border-color;
  }
}

// fix legibility of btn-outline-warning
.btn-outline-warning {
  border-color: $warning-button-color;
  color: $warning-button-color;
}

// fix legibility of btn-outline-info
.btn-outline-info {
  border-color: $cyan;
  color: $info-button-color;

  &:hover,
  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active {
    border-color: $cyan;
  }
}


//
// Button sizes
//

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

.btn-xxs {
  @include button-size($btn-padding-y-xxs, $btn-padding-x-xxs, $btn-font-size-xxs, $btn-border-radius-xxs);
}

.btn-xxxs {
  @include button-size($btn-padding-y-xxxs, $btn-padding-x-xxxs, $btn-font-size-xxxs, $btn-border-radius-xxxs);
}

// Responsive button sizes (e.g. btn-sm-xs to use an xs button size for sm and smaller breakpoints)
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .btn-#{$breakpoint}-xs {
      @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
    }

    .btn-#{$breakpoint}-sm {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
    }

    .btn-#{$breakpoint}-md {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    }
  }
}


//
// Button spacing
//

.btn-short {
  padding-bottom: 0;
  padding-top: 0;
}

// Margin above .btns (not following a header)
:not(h1, h2, h3, h4, h5, h6, .card-title) + .btns {
  margin-top: 1.5rem;
}
