@use 'sass:color';

// Animate a yellow background glow to highlight that an element state changed.
.changed-glow {
  animation: changed-glow 2s linear;
}

$glow-color: color.adjust($yellow, $alpha: -0.1);

@keyframes changed-glow {
  50% {
    background: $glow-color;
    border-radius: $border-radius;
    box-shadow: 0 0 5px $glow-color, inset 0 0 2px $glow-color;
  }
}
