// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Group margins (e.g., where `.mb-g1` is group version of `.mb-1`) for
    // multiple child elements that might or might not wrap.  This applies
    // negative margin to the parent element, and the same positive margin
    // to the direct children.
    // https://jaketrent.com/post/spacing-between-multiline-flexbox-child-elements/
    @each $size, $length in $spacers {
      @if $size != 0 {
        .m#{$infix}-g#{$size} {
          margin: -$length !important;

          > * {
            margin: $length !important;
          }
        }
        .mt#{$infix}-g#{$size},
        .my#{$infix}-g#{$size} {
          margin-top: -$length !important;

          > * {
            margin-top: $length !important;
          }
        }
        .me#{$infix}-g#{$size},
        .mx#{$infix}-g#{$size} {
          margin-right: -$length !important;

          > * {
            margin-right: $length !important;
          }
        }
        .mb#{$infix}-g#{$size},
        .my#{$infix}-g#{$size} {
          margin-bottom: -$length !important;

          > * {
            margin-bottom: $length !important;
          }
        }
        .ms#{$infix}-g#{$size},
        .mx#{$infix}-g#{$size} {
          margin-left: -$length !important;

          > * {
            margin-left: $length !important;
          }
        }
      }
    }
  }
}
