// Min-height elements
// Bootstrap only provides built-in classes for setting min-height in vw units.
// Create classes for setting pixel-based min-height at standard breakpoints.
// Examples: .min-h-550px, .min-h-lg-550px

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size in $extremum-sizes {
      .min-h#{$infix}-#{$size} { min-height: $size !important; }
      .max-h#{$infix}-#{$size} { max-height: $size !important; }
      .min-w#{$infix}-#{$size} { min-width: $size !important; }
      .max-w#{$infix}-#{$size} { max-width: $size !important; }
    }
  }
}
