// Styles applied to the page footer.
//
// NOTE: this file should not be used as a reference for writing sass.  It targets
// element types to allow for simpler HTML in CMS snippets.  In general and when
// writing HAML, prefer using explicit class names.

.footer {
  a {
    color: $body-color;
  }
}

.footer-nav-list {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .list-unstyled;

  > li {
    padding-bottom: $spacer;
  }
}
