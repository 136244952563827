/* stylelint-disable selector-class-pattern */

.splide__arrow[disabled] {
  opacity: 0.3;
}

// Display arrows overlapping edge of slider in both normal and top-to-bottom mode
.splide__arrow--next {
  right: -1em;
}

.splide__arrow--prev {
  left: -1em;
}

.splide__slide .card:last-child {
  margin-bottom: 0 !important;
}
