.spinner-overlay {
  height: 100%;
  left: 0;
  padding-top: $spacer * 2;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $zindex-spinner-container;
}

.spinner-backdrop {
  background-color: $gray-100;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
}
