// Undo fpf-specific styling so that a div tree can use more generic styling
.reset-style {
  * {
    all: revert;
  }

  p {
    margin: 0;
  }
}
