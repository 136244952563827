.image-placeholder {
  background-color: $gray-200;
  height: 100%;
  width: 100%;
}

// Dimensions of Active Storage image variants
$image-sizes: (
  // fit|fill, width, height
  ('fit', 126, 81),
  ('fit', 140, 90),
  ('fill', 60, 60),
  ('fill', 200, 200),
);

/* stylelint-disable scss/no-global-function-names */
// the sass:lint module is not available in the version of sass that we use:
// https://sass-lang.com/documentation/modules/list/
@each $size in $image-sizes {
  .image-#{nth($size, 1)}-#{nth($size, 2)}-#{nth($size, 3)} {
    height: #{nth($size, 3)}px;
    width: #{nth($size, 2)}px;
  }

  .image-max-#{nth($size, 1)}-#{nth($size, 2)}-#{nth($size, 3)} {
    max-height: #{nth($size, 3)}px;
    max-width: #{nth($size, 2)}px;
  }
}

.image-overlay-text {
  color: $white;
  font-size: 1.5rem;
  left: 50%;
  max-width: 80%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
