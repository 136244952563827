@use 'sass:map';

// Width and height

.w-100px {
  width: 100px;
}

.w-150px {
  width: 150px;
}

.mw-0 {
  min-width: 0;
}

.max-vh-75 {
  max-height: 75vh;
}

.max-w-60 {
  max-width: 60%;
}

// Make the width utility responsive
// stylelint-disable indentation, function-parentheses-space-inside
$utilities: map.merge(
  $utilities,
  (
    'width': map.merge(
      map.get($utilities, 'width'),
      ( responsive: true ),
    ),
  )
);
// stylelint-enable indentation, function-parentheses-space-inside
