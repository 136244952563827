// Stack multiple icons using fa-stack-2x at the normal size
// https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/stacking-icons
.fa-stack-half {
  font-size: $font-size-half;
  vertical-align: middle;
}

.icon-offset-top-right {
  left: 7px;
  position: absolute;
  top: -7px;
}

// Swap display of collapsed-show and collapsed-hide elements, e.g. disclosure
// triangle icons.
//
// Note that the collapsed class should be rendered in the view, since otherwise
// it's not added by Bootstrap until the collapse element has been opened and
// closed again.
[data-bs-toggle='collapse'] {
  .collapsed-hide {
    display: inherit;
  }

  .collapsed-show {
    display: none;
  }

  // stylelint-disable max-nesting-depth
  &.collapsed {
    .collapsed-hide {
      display: none;
    }

    .collapsed-show {
      display: inherit;
    }
  }
}
